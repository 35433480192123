<template>
	<div class="padding-top-50 padding-lr-80" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="w100">
			<el-row :gutter="24">
				<el-col :span="7">
					<div class="text-right">
						<el-table :data="tableData" style="width: 100%">
							<el-table-column label="章节名称" >
								<template slot-scope="scope"  >
									<div class="flex align-center">
										<el-tag size="mini" type="warning" class="margin-right-xs" v-if="scope.row.is_order==1">序</el-tag>
										<div class="text-cut w100">{{ scope.row.title }}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="操作"  align="right">
								<template slot-scope="scope">
									<div class="flex justify-end">
										<el-button size="mini" type="warning" @click="editCap(scope.row)">编辑</el-button>
										<el-button size="mini" type="danger" @click="deleteCap(scope.row)">删除</el-button>
										<el-button type="primary" size="mini" @click="setFirst(scope.row)">{{scope.row.is_order==1 ? '取消序' :'设置为序'}}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-col>
				<el-col :span="17">
					<div class="flex justify-between align-center margin-bottom">
						<p class="text-bold fs18">{{id ? '编辑' : '添加'}}章节</p>
						<el-button-group>
						  <el-tooltip class="item" effect="dark" content="点击保存该章节编辑内容" placement="bottom">
						        <el-button type="primary" @click="submitForm(0)">保存</el-button>
						  </el-tooltip>
						  <el-tooltip class="item" effect="dark" content="制作完成,发布该章节" placement="bottom">
						        <el-button type="danger" @click="submitForm(1)">发布</el-button>
						  </el-tooltip>
						  <el-tooltip class="item" effect="dark" content="创建新的章节" placement="bottom">
						        <el-button type="warning" @click="addNewCap">新建</el-button>
						  </el-tooltip>
						</el-button-group>
					</div>
					<el-input type="text"  class="input    radius-8" v-model="title" placeholder="请输入章节标题" ></el-input>
					<div class="flex justify-end">
						<el-button class="margin-top-sm " size="mini" >字数统计：{{wordNumber || 0}}</el-button>
						
					</div>
					<el-input type="textarea" rows="30" v-model="body" class="margin-top"   placeholder="请输入章节内容"></el-input>
				</el-col>
			</el-row>
			
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				fullscreenLoading:false,
				id:'', //章节id
				aid:'', //电子书id
				title: '', //章节标题
				body:'' ,//章节内容
				is_reset:0, //1  编辑   0 新增
				is_order:0, //1  是   0 否
				tableData: []
			}
		},
		computed: {
			wordNumber() {
				if(this.body){
					return this.body.length
				}else{
					return 0
				}
			}
		},
		mounted(){
			this.aid = this.$route.query.aid
			if(this.$route.query.id){
				this.fullscreenLoading = true
				this.id = this.$route.query.id;
				this.getSectionDetail(this.$route.query.id);
			}
			if(this.$route.query.is_reset){
				this.is_reset = this.$route.query.is_reset;
			}
			if(this.$route.query.is_order){
				this.is_order = this.$route.query.is_order;
			}
			this.getLogLists()
		},
		methods: {
			// 新增章节
			addNewCap(){
				this.is_reset = 0;
				this.title = '';
				this.id = '';
				this.body = '';
			},
			// 编辑章节
			editCap(item){
				this.id = item.id;
				this.is_reset = 1;
				this.is_order = item.is_order;
				this.getSectionDetail(item.id)
			},
			// 删除章节
			deleteCap(item){
				this.$confirm('此操作将永久删除该章节, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.ajax('post', '/v1/61cbcc6bb2407', {
					id: item.id
				  }, res => {
					this.loading = false;
					if (res.code == 1) {
						this.getLogLists()
					} else {
						this.$message.error(res.msg);
					}
							
				  }, err => {
					
				  })
				}).catch(() => {
						   
				});
				
			},
			// 章节设置为序
			setFirst(item){
				let is_order = item.is_order==0 ? 1 : 0;
				let tip = item.is_order==0 ? '确定设置为序？' : '确定取消设置为序'
				this.$confirm(tip, '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.ajax('post', '/v1/6201d1ec55643', {
					id: item.id,
					is_order:is_order
				  }, res => {
					this.loading = false;
					if (res.code == 1) {
						this.$message.success(res.msg);
						item.is_order = item.is_order==0 ? 1 : 0;
						this.getLogLists()
					} else {
						this.$message.error(res.msg);
					}
							
				  }, err => {
					
				  })
				}).catch(() => {
						   
				});
				
			},
			// 获取章节列表
			getLogLists() {
				this.ajax('post', '/v1/61c6b084561cd', {
					page: 1,
					document_id: this.aid,
					order: 'asc'
				}, res => {
					this.loading = false;
					if (res.code == 1) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
			
				}, err => {
					
				})
			
			},
			// 获取章节详情
			getSectionDetail(section_id) {
				this.ajax('post', '/v1/Document/getDocumentBody', {
					id: section_id
				}, res => {
					this.fullscreenLoading = false;
					if (res.code == 1) {
						this.body = res.data.body;
						this.title = res.data.title;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 保存 发布章节
			submitForm(submit_type) {
				if (!this.title) {
					this.$message.error('请输入章节标题');
					return;
				}
				if (!this.body) {
					this.$message.error('请输入章节内容');
					return;
				}
				if(submit_type==1 && !this.id){
					this.$message.error('请先保存');
					return;
				}
				this.ajax('post', '/v1/61c562909ac92', {
					id:this.id || '', //章节id  编辑时传
					body:this.body,
					title:this.title,
					document_id:this.aid,
					is_push:submit_type,//0保存，1发布
					is_reset:this.is_reset //1  编辑   0 新增
				}, res => {
					this.fullscreenLoading = false;
					if (res.code == 1) {
						this.getLogLists();
						this.$message.success(res.msg);
						this.is_reset = 1;
						this.id = res.data.id
					} else {
						this.$message.error(res.msg);
					}
				})
			
			},
		},
	}
</script>

<style scoped>

</style>
